import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import "../styles/_competitiveIntelligence.scss";
import contributions from "../images/ContributionsSingle.png";
import ogImage from "../images/OG_Image.png";
import flowvectors from "../images/SingleFlowVectors.png";

const CompetitiveIntelligence = () => (
  <Layout>
    <SEO
      title="Competitive Intelligence"
      ogImage={ogImage}
      keywords={[
        "Flowspring",
        "competitive intelligence",
        "product",
        "feature",
        "analytics",
      ]}
      description="Flowspring's unique Flow Vector methodology allows you to identify which funds you are winning and losing assets from"
    />
    <div className="content">
      <div className="competitionStep">
        <div>
          <div className="competitionStepNumber">1</div>
          <h2 className="competitionStepTitle">Discover your Competitors</h2>
        </div>
        <div className="competitionStepBody">
          <div className="competitionStepText">
            <p>
              If you think you&apos;re just competing with funds within your
              category, you&apos;re missing a lot. Flowspring identifies your
              competitors as the funds that you are winning or losing the most
              assets from - this can include funds outside your category
              entirely.
            </p>
            <p>
              Furthermore the magnitude with which you compete with other
              firms can be staggeringly uneven, leading to excellent
              opportunities to focus your efforts on individual fund-to-fund
              comparisons your investors are making.
            </p>
          </div>
          <img
            alt="Graphs of flows verses comptetitors"
            className="competitionStepImage"
            src={flowvectors}
          />
        </div>
      </div>

      <div className="competitionStep">
        <div className="competitionStepNumber">2</div>
        <h2 className="competitionStepTitle">
          Understand why you&apos;re growing at a different rate than your
          competitors
        </h2>

        <div className="competitionStepBody">
          <div className="competitionStepText">
            <p>
              Products and investor preferences for those products are
              constantly changing. Flowspring&apos;s factor model of fund flows can
              disentangle the effects of fees, investment performance,
              category preferences, and dozens more factors that drive organic
              growth rates. We make it easy to identify why you are winning or
              losing to a given competitor.
            </p>
          </div>
          <img
            alt="Graph of flow growth verses time"
            className="competitionStepImage"
            src={contributions}
          />
        </div>
      </div>

      <div className="competitionStep">
        <div className="competitionStepNumber">3</div>
        <h2 className="competitionStepTitle">
          Take action to stem outflows and magnify inflows
        </h2>
        <div className="competitionStepBody">
          <div className="competitionStepText">
            <p>
              Once you&apos;ve identified your key competitors and why they&apos;re
              winning or losing assets to you, formulate a plan to maximize
              inflows and minimize outflows. This could involve new marketing
              collateral, sales incentives, a new fee structure, or even
              production rationalization.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CompetitiveIntelligence;
